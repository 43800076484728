export const NavLinksData = [
     {
         title: 'Home',
         path: '/'
     },
     {
         title: 'About Me',
         path: '/about'
     },
     {
         title: 'Projects',
         path: '/projects'
     },
     {
         title: 'Contact',
         path: '/contact'
     }
 ]